.cart-sum {
  padding: 30px;
  border: 1px solid $border-color;

  &__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 600;
    color: $primary;
    position: relative;
    cursor: pointer;
    margin-bottom: 8px;

    &::after {
      content: "\f107";
      font-family: fontawesome;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      color: #666;
      border: 1px solid $border-color;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.active::after {
      transform: rotateX(180deg);
    }
  }

  &__content {
    display: none;
    padding-bottom: 16px;
  }

  &__footer {
    border-top: 1px solid $border-color;
    margin-top: 30px;
    padding-top: 30px;
  }

  &__btn {
    @extend .button;
    width: 100%;
    text-align: center;
    padding: 14px 33px;
    background-color: $success;
  }

  &__price {
    font-size: 18px;
    font-weight: 600;
  }

  &__price-lg {
    font-size: 24px;
    font-weight: 600;
  }
}

.form-styled {
  .form-group {
    margin-bottom: 20px;
  }

  .form-control,
  .custom-select {
    background-color: $light;
    border-radius: 0;

    &:focus {
      border-color: #333;
      background-color: $white;
    }
  }
}

.checkout {
  &__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 24px;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    margin-bottom: 20px;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.step {
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    background-color: #f2f2f2;
    line-height: 30px;
    padding: 14px 30px;
    border: 1px solid transparent;
    position: relative;
    z-index: 1;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:first-child {
      z-index: 2;

      &::before,
      &::after {
        content: "";
        display: block;
        border-style: solid;
        border-width: 30px;
        border-color: transparent transparent transparent #f2f2f2;
        position: absolute;
        top: -1px;
        left: 100%;
      }

      &.active {
        background-color: $white;
        border-color: $border-color;

        &::before {
          border-left-color: $border-color;
        }

        &::after {
          border-width: 29px;
          top: 0;
          margin-left: -1px;
          border-left-color: #fff;
        }
      }
    }

    &:last-child {
      padding-left: 60px;

      &::before,
      &::after {
        content: "";
        display: block;
        border-style: solid;
        border-width: 30px;
        border-color: transparent transparent transparent $white;
        position: absolute;
        top: -1px;
        left: -1px;
      }

      &.active {
        background-color: $white;
        border-color: $border-color;

        &::before {
          border-left-color: $border-color;
        }

        &::after {
          margin-left: -1px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &__item {
      font-size: 16px;
      padding: 14px 20px;
      line-height: 1.3;
      justify-content: center;

      &:last-child {
        padding-left: 20px;
      }

      &::before,
      &::after {
        display: none !important;
      }
    }
  }
}
