.ct-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    img {
      height: 50px;
    }
  }

  &__label {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  &__text {
    color: #888;
    text-align: center;
  }
}
