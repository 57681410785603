.logo {
  display: inline-flex;
  padding: 20px 0;

  img {
    height: 30px;
  }

  @media (min-width: 1200px) {
    padding: 25px 40px;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    align-self: stretch;
  }
}

.header {
  position: relative;
  z-index: 100;
  height: 70px;

  &__wrapper {
    width: 100%;
    background-color: $white;
    @include shadow("xs");
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 18px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      left: 50%;
      bottom: 50%;
      z-index: 3;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }

    .fa,
    span {
      transition: 0.3s;
    }

    &.active .fa,
    &.active span {
      opacity: 0;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 24px;
      border-bottom: 2px solid #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: 0.3s;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.active::before,
    &.active::after {
      opacity: 1;
    }
  }

  @media (min-width: 1200px) {
    border-top: 50px solid #181818;
    height: 191px;

    &__wrapper {
      position: static;
      box-shadow: unset;
      border-bottom: 1px solid $border-color;
    }

    &__inner {
      position: relative;
      padding-bottom: 60px;

      .menu-root {
        width: 100%;
        border: 1px solid $border-color;
        border-bottom: 0;
        padding: 0 20px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &__btn {
      width: 80px;
      height: 80px;
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      color: #999;
      transition: 0.3s;

      &:hover {
        color: #000;
      }

      & + & {
        border-left: 0;
      }
    }

    &.is-fixed &__wrapper {
      position: fixed;
      border-bottom: 0;
      @include shadow("xs");
    }

    &.is-fixed &__btn {
      display: none;
    }

    &.is-fixed &__inner {
      padding-bottom: 0;

      .logo {
        display: none;
      }

      .menu-root {
        position: static;
        border: 0;
        padding: 0;

        & > .menu-item > .menu-link {
          line-height: 60px;
        }
      }
    }

    &.is-fixed {
      .search {
        display: none !important;
      }
    }
  }

  @media (min-width: 1500px) {
    height: 131px;

    &__inner {
      padding-bottom: 0;

      .menu-root {
        padding: 0 40px;
        position: static;
        border: 0;

        & > .menu-item > .menu-link {
          line-height: 80px;
        }
      }
    }
  }
}

.navigation {
  background-color: $light;

  &__inner {
    position: relative;
  }
}

.search {
  display: none;
  width: 100%;
  padding: 15px 0;
  background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .form-control {
    height: 40px;
    border: 0;
    border-radius: 0;

    &::placeholder {
      color: #999;
    }
  }

  .input-group-text {
    background-color: $white;
    border-radius: 0;
    border: 0;
    font-size: 18px;
  }

  &.show {
    display: block;
    animation: fade-in 0.3s ease-in-out;
  }

  @media (min-width: 1200px) {
    display: none;
    width: calc(100% - 240px);
    background-color: $light;
    padding: 20px 0;
    border-left: 1px solid $border-color;

    .form-control {
      background: 0;
      padding-left: 30px;
    }

    .input-group-text {
      font-size: 20px;
      padding: 0 30px 0 0;
      background: 0;
    }

    .input-group-append:last-child {
      display: none;
    }
  }
}

.h-dropdown {
  display: none;
  background-color: $light;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 300px;
  max-width: 100%;
  padding: 10px 0;
  border: 1px solid $border-color;
  @include shadow("xs");
  transition: 0.3s;
  transform-origin: 100% 0;

  &.show {
    display: block;
    animation: menu-float-show 0.3s ease-in-out;
  }

  &__item {
    display: block;
    font-size: 12px;
    line-height: 20px;
    padding: 8px 30px;
    color: #888;

    &:not(:last-child) {
      border-bottom: 1px dashed $border-color;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__body {
    padding: 20px;

    & + & {
      border-top: 1px solid $border-color;
    }
  }

  &__label {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  &__total {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 20px;

    span {
      font-size: 22px;
      margin-left: 8px;
      color: #000;
    }
  }

  &__btn {
    @extend .button;
    display: block;
    text-align: center;

    & + & {
      margin-top: 8px;
    }

    &--dark {
      background-color: #000;

      &:hover {
        background-color: $primary;
      }
    }
  }
}

.toppage {
  background-color: $light;
  border-bottom: 1px solid #ccc;

  &__text {
    padding: 10px 20px;
    color: $primary;
    border-bottom: 1px solid $border-color;
  }

  @media (min-width: 1200px) {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    position: absolute;
    bottom: 100%;
    left: 0;
    background: 0;

    &__text {
      margin-left: auto;
      margin-right: 16px;
      border: 0;
      padding: 0;
    }
  }
}

.h-hotline {
  &__item {
    padding: 10px 20px;
    color: #888;
    border-bottom: 1px solid $border-color;

    a {
      color: inherit;
      transition: 0.3s;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 1200px) {
    display: flex;

    &__item {
      padding: 0;
      border: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }

      .fa {
        color: $white;
      }
    }
  }
}

.h-social {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;

  &__item {
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    color: #333;
    transition: 0.3s;
    font-size: 16px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  @media (min-width: 1200px) {
    padding: 0;

    &__item {
      color: $white;
    }
  }
}
