.post {
  @include clearfix;

  &-title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 1.3;
    margin-bottom: 24px;
  }

  &-frame {
    width: 50%;
    margin-right: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    float: left;
    margin-right: 30px;

    img {
      display: block;
      width: 100%;
    }
  }

  &-content {
    color: #888;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px;
  }

  @media (max-width: 1199px) {
    &-title {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    &-title {
      letter-spacing: 2px;
    }

    &-frame {
      width: 100%;
      margin-right: 0;
      padding-right: 0;
    }
  }
}
