.preview {
  &__thumbs {
    margin-top: 16px;
  }

  @media (max-width: 575px) {
    &__thumbs {
      margin-top: 8px;
    }
  }
}

.preview-slider {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 80px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -80px;
    z-index: 10;
    transform: translateY(-50%);
    opacity: 0;
    transition: 0.4s;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -80px;
  }

  &:hover &__prev {
    opacity: 1;
    left: 0;
  }

  &:hover &__next {
    opacity: 1;
    right: 0;
  }

  &__frame {
    @include frame("rect", 124%);
    max-width: 569px;
    margin: 0 auto;
  }

  .swiper-slide {
    background-color: $white;
  }

  @media (max-width: 1199px) {
    &__frame {
      max-width: 420px;
    }
  }
}

.thumb-slider {
  position: relative;

  &__frame {
    @include frame("rect", 124%);
  }

  &.has-nav {
    padding: 0 20px;
  }

  &.has-nav &__prev,
  &.has-nav &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 100%;
    background-color: #ddd;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:focus {
      outline: 0;
    }
  }

  &.has-nav &__next {
    left: auto;
    right: 0;
  }

  &:not(.has-nav) &__prev,
  &:not(.has-nav) &__next {
    display: none;
  }

  .swiper-slide {
    border: 1px solid transparent;
    opacity: 0.5;

    &-thumb-active {
      opacity: 1;
      border: 1px solid $primary;
    }
  }
}
