.breadcrumb {
  background: 0;
  padding: 8px 0;

  &-item {
    &,
    &.active {
      color: #888;
    }

    & + &::before,
    &.active::before {
      color: #ccc;
    }

    a {
      color: $primary;

      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }

  &--white .breadcrumb-item,
  &--white .breadcrumb-item + .breadcrumb-item::before,
  &--white .breadcrumb-item + .breadcrumb-item.active,
  &--white .breadcrumb-item + .breadcrumb-item.active::before,
  &--white .breadcrumb-item a,
  &--white .breadcrumb-item a:hover {
    color: $white;
  }
}
