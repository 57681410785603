.section-2 {
  margin-bottom: 50px;

  &__title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.section-3 {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: #222;
    line-height: 1.2;
    margin-bottom: 24px;
  }

  &__intro {
    border: 1px solid $border-color;
    padding: 30px;
    color: #888;
    align-items: center;
    margin-bottom: 24px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    &__intro {
      flex-direction: column;
      padding: 20px;
      align-items: stretch;
    }

    &__logo {
      width: 100%;
      border-bottom: 1px solid $border-color;
      margin-bottom: 20px;
      padding-bottom: 20px;
      margin-right: 0;
      height: 80px;
    }
  }
}

.section-4 {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 0;
  }

  &__elements {
    display: flex;
    align-items: center;
  }
}

.section-5 {
  padding: 50px 0;

  &__title {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 6px;
    line-height: 1.2;
    margin-bottom: 50px;
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 40px;
    }
  }

  @media (max-width: 991px) {
    padding: 30px 0;

    &__title {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
}

.section-6 {
  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
    letter-spacing: 6px;
  }
}

.aside {
  &:not(:last-child) {
    margin-bottom: 50px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    color: #888;
  }
}
