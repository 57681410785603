.pagination {
  margin-bottom: 0;

  .page-item {
    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .page-link {
    display: inline-flex;
    min-width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    color: $gray-800;
    padding: 0 10px;
    border-radius: 0 !important;
  }

  @media (max-width: 767px) {
    .page-link {
      min-width: 30px;
      height: 30px;
    }
  }
}
