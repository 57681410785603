.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(.menu-sub) {
    border-bottom: 1px solid #aaa;
  }

  &-item {
    position: relative;
    border-top: 1px solid #aaa;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-left: 1px solid #aaa;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    &::before {
      content: "\f105";
      font-family: "fontawesome";
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      color: #666;
      font-size: 18px;
      border-radius: 40px;
      // background-color: rgba(#000, 0.1);
      transition: 0.3s ease-out;
    }

    &.active {
      // background-color: rgba(#15191e, 0.08);

      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &-sub {
    display: none;
    // background-color: $gray-100;
    background-color: rgba(#15191e, 0.08);

    .menu-link {
      padding-left: 35px;
      position: relative;
    }
  }

  &-sub-2 {
    // background-color: $gray-200;

    .menu-link {
      padding-left: 45px;
    }
  }

  &-sub-3 {
    // background-color: $gray-300;

    .menu-link {
      padding-left: 55px;
    }
  }

  &-collections {
    display: none;
  }

  @media (min-width: #{$navbar-breakpoint}px) {
    &:not(.menu-sub) {
      border: 0;
    }

    &-item {
      border: 0;
    }

    // menu root
    &-root {
      display: flex;
      border: 0;
    }

    &-root > &-item {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }

    &-root > &-item > &-toggle {
      display: none;
    }

    &-root > &-item > &-link {
      padding: 0;
      line-height: 60px;
      color: #888;
      font-weight: 700;
    }

    &-root > &-item:hover > &-link {
      color: #000;
    }

    &-root > &-item-mega > &-link,
    &-root > &-item-group > &-link {
      &::after {
        content: "\f107";
        display: inline-block;
        font-family: fontawesome;
        margin-left: 4px;
      }
    }

    &-root > &-item-group &-item:hover > &-link {
      background-color: $light;
    }

    &-root > &-item-group &-sub {
      min-width: 200px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: -11px;
      left: 100%;
      transform-origin: 0 0;
      background-color: $light;
      padding: 10px 0;
      border: 1px solid $border-color;

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root > &-item-group > &-sub {
      transform-origin: 0 0;
      top: 100%;
      left: 0;
    }

    &-root > &-item-group &-sub &-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }

    &-root > &-item-group &-sub &-toggle {
      width: 30px;
      height: 36px;
      border-left: 0;
    }

    &-root > &-item-group &-sub &-link {
      font-size: 12px;
      line-height: 20px;
      padding: 8px 30px;
      color: #888;

      &:hover {
        color: $primary;
      }
    }

    &-root > &-item-group &-item-group > &-link {
      padding-right: 30px;
    }

    &-root > &-item-group:hover > &-sub,
    &-root > &-item-group &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    // menu mega
    &-item-mega {
      position: static;
    }

    &-item-mega:hover &-mega {
      display: grid;
      animation: fade-in 0.2s ease-out;
    }

    &-mega {
      display: none;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      width: 100%;
      background-color: $white;
      z-index: 50;
      padding: 20px;
      transform-origin: top left;
      position: absolute;
      top: 100%;
      left: 0;

      &.show {
        display: block;
        animation: fade-in 0.2s ease-out;
      }

      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        @include shadow("xs");
        background-color: $white;
      }
    }

    &-mega &-sub {
      display: block;
      position: static;
      box-shadow: unset;
      border-radius: 0;
      background: 0;
      min-width: unset;
      top: auto;
      left: auto;
    }

    &-mega &-link {
      white-space: normal;
      padding: 0;
      font-size: 13px;
      line-height: 20px;
    }

    &-mega &-link:hover {
      color: $primary;
    }

    &-mega &-toggle {
      display: none;
    }

    &-mega &-sub &-sub {
      display: block;
      margin-top: 4px;
    }

    &-mega &-sub &-sub &-link {
      padding: 4px 0 4px 20px;
      position: relative;
      left: 0;
      transition: 0.3s;

      &:hover {
        left: 5px;
      }

      &::before {
        content: "\f105";
        font-family: fontawesome;
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }

    &-mega > &-sub {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }

    &-mega > &-sub > &-item > &-link {
      font-weight: 700;
      text-transform: uppercase;
    }

    &-collections {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
    }

    // menu float
    &-float {
      width: 230px;
      background-color: $white;
      @include shadow("");
      position: absolute;
      top: 100%;
      left: 0;
    }

    &-float > &-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }

    &-float > &-item:hover > &-link {
      background-color: $light;
    }

    &-float > &-item-group > &-link,
    &-float > &-item-mega > &-link {
      padding-right: 30px;
      position: relative;

      &::before {
        content: "\f105";
        font-family: "fontawesome";
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        color: #666;
        font-size: 18px;
        border-radius: 40px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }

    &-float > &-item > &-toggle {
      display: none;
    }

    // --------- float submenu
    &-float > &-item-group &-item:hover > &-link {
      background-color: $light;
    }

    &-float > &-item-group &-sub {
      min-width: 200px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      border-radius: 4px;
      transform-origin: 0 4px;
      background-color: $white;

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-float > &-item-group &-sub &-item {
      &:not(:last-child) {
        border-bottom: 1px dashed $border-color;
      }
    }

    &-float > &-item-group &-sub &-toggle {
      width: 30px;
      border-left: 0;
    }

    &-float > &-item-group &-sub &-link {
      padding-left: 20px;
    }

    &-float > &-item-group &-item-group > &-link {
      padding-right: 30px;
    }

    &-float > &-item-group:hover > &-sub,
    &-float > &-item-group &-item-group:hover > &-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    &-float &-mega {
      width: 890px;
      top: 0;
      left: 100%;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1300px) {
    &-full-width > &-item:last-child > &-sub {
      left: auto;
      right: 0;
      transform-origin: 100% 0;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1600px) {
    &-full-width > &-item:last-child > &-sub &-sub {
      left: auto;
      right: calc(100% + 4px);
      transform-origin: 100% 4px;
    }
  }
}

.h-menu {
  &__toggle {
    display: none;
  }

  @media (min-width: 1200px) {
    position: relative;

    &__toggle {
      display: block;
    }
  }
}
