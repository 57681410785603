.m-last-0:last-child {
  margin: 0 !important;
}

.p-last-0:last-child {
  padding: 0 !important;
}

.border-last-0:last-child {
  border: 0 !important;
}

// For hidden h1 tag
.hidden-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.container {
  @media (min-width: 1200px) {
    max-width: 100%;
    padding: 0 60px;
  }
}

.styled-content {
  color: #888;

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin-bottom: 14px;
  }

  p {
    padding-left: 28px;
  }

  ul,
  ol {
    padding-left: 45px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
