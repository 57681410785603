.brands {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  &__item {
    display: block;
    margin: 0;
    position: relative;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }

  &__frame {
    display: block;
    position: relative;
    z-index: 1;
    border: 1px solid $border-color;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 67%;
    }

    img {
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      filter: saturate(0);
      opacity: 0.5;
    }

    &:hover img {
      filter: saturate(1);
      opacity: 1;
    }
  }

  &__name {
    text-align: center;
    margin-top: 10px;
  }

  input:checked ~ &__frame img {
    opacity: 1;
    filter: saturate(1);
  }

  input:checked ~ &__name {
    color: $primary;
  }
}

.filter {
  background-color: $white;
  border: 1px solid $border-color;

  &__group {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  &__header {
    font-size: 16px;
    font-weight: 700;
    padding: 5px 35px 5px 20px;
    position: relative;
    cursor: pointer;

    &::after {
      content: "\f107";
      font-family: fontawesome;
      color: #888;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    &.active::after {
      transform: translateY(-50%) rotateX(180deg);
    }
  }

  &__body {
    display: none;
    border-top: 1px solid $border-color;
    background-color: $light;
    padding: 20px;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    color: #888;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    input:checked ~ span {
      font-weight: 700;
      color: $primary;
    }
  }

  &__selected {
    display: flex;
    justify-content: space-between;
    color: #888;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    a:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

#slider-range {
  .ui-slider-handle {
    border-radius: 50% !important;
    background: #fff !important;
    border: 2px solid $primary !important;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  .ui-slider-range {
    background: #f1594b;
  }
}

.filter-btn {
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 100;
  @include shadow("");

  @media (min-width: 992px) {
    display: none;
  }
}

.sort {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid $border-color;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 24px;

  &__block {
    display: flex;
    align-items: center;

    label {
      font-weight: 700;
      margin-bottom: 0;
      white-space: nowrap;
      cursor: pointer;
    }

    .custom-select {
      border-radius: 0;
      background-color: $light;
    }
  }

  &__direction {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #888;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    .fa-sort-amount-desc {
      display: none;
    }

    input:checked ~ .fa-sort-amount-asc {
      display: none;
    }

    input:checked ~ .fa-sort-amount-desc {
      display: inline-flex;
    }
  }

  &__text {
    color: #888;
  }

  label {
    color: #888;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;

    &__block {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

.compare {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__name {
    display: inline-block;
    color: #888;
    transition: 0.3s;
    margin-right: 8px;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.pd-info {
  &__state {
    color: $success;
    margin-bottom: 8px;
  }

  &__sku {
    color: #888;
    margin-bottom: 10px;
  }

  &__name {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &__stars {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    font-size: 12px;
    color: $primary;

    a {
      display: inline-block;
      margin-left: 20px;
      position: relative;

      &::before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10px;
        border-left: 1px solid $border-color;
      }
    }
  }

  &__desc {
    color: #888;
    margin-bottom: 24px;
  }

  &__price {
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    color: #111;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 24px;

    del {
      font-size: 0.7em;
      margin-left: 10px;
      font-weight: 400;
      color: #888;
    }
  }

  &__brands {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    border: 1px solid $border-color;
    padding: 20px;
    margin-bottom: 24px;
  }

  &__block {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 24px 0 40px;
    margin: 40px 0;
  }

  &__quantity {
    background-color: $light;
    border: 1px solid #dedede;
    text-align: center;
    width: 80px;
    height: 54px;
    font-size: 16px;
  }

  &__cta {
    @extend .button;
    padding: 17px 33px;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid $border-color;
    color: #888;
    transition: 0.3s;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      color: $primary;
      border-color: $primary;
      text-decoration: none;
    }
  }

  .form-group {
    .custom-select {
      max-width: 300px;
      border-radius: 0;
      background-color: $light;
    }
  }
}

.detail-tabs {
  border-left: 1px solid $border-color;
  box-shadow: inset 0 -1px 0 $border-color;
  margin-bottom: 40px;

  .nav-link {
    border: 1px solid $border-color;
    border-left: 0;
    font-weight: 600;
    padding: 9px 20px;
    color: #888;

    &.active {
      color: $primary;
    }
  }
}

.star-rating {
  display: flex;
  align-items: center;
  padding-left: 60px;

  & + & {
    margin-top: 4px;
  }

  &__label {
    min-width: 70px;
    margin-right: 10px;
  }
}

.review-form {
  max-width: 1170px;

  .form-group {
    margin-bottom: 20px;
  }

  .form-control,
  .custom-select {
    background-color: $light;
    border-radius: 0;

    &:focus {
      border-color: #222;
      background-color: $white;
    }
  }
}
