@mixin slider-nav-1 {
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    font-size: 18px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translateY(-50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  &:hover &__prev,
  &:hover &__next {
    background-color: rgba(#fff, 1);
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.5);
    }
  }
}

@mixin slider-nav-2 {
  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $border-color;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translate(-50%, -50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__next {
    left: 100%;
  }
}

@mixin partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    padding: 10px 5px;
    font-size: 18px;
    color: $secondary;
    font-size: 24px;
    color: $primary;
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  &__frame {
    display: block;
    position: relative;
    border: 1px solid $border-color;
    background-color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $border-color;
      font-size: 20px;
      line-height: 1;
    }

    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.product-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    font-size: 20px;
    color: #888;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 10;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $primary;
    }

    &.swiper-button-disabled {
      opacity: 0.4;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  &__pagination {
    display: flex;
    position: absolute;
    z-index: 10;
    top: -40px;
    right: 0;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      opacity: 1;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &-active {
        background-color: #000;
      }
    }
  }

  @media (max-width: 767px) {
    &__prev,
    &__next {
      width: 30px;
      height: 30px;
    }

    &__pagination {
      top: -16px;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }
}

.news-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #fff;
    font-size: 20px;
    color: #888;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 10;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $primary;
    }

    &.swiper-button-disabled {
      opacity: 0.4;
    }
  }

  &__next {
    left: auto;
    right: 0;
  }

  @media (max-width: 767px) {
    &__prev,
    &__next {
      width: 30px;
      height: 30px;
    }
  }
}

.product-slider-2 {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border: 1px solid $border-color;
    font-size: 18px;
    cursor: pointer;
    background-color: $white;
    position: absolute;
    top: -45px;
    right: 0;

    &:hover {
      border-color: #000;
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
    }
  }

  &__prev {
    right: 35px;
  }

  .swiper-slide {
    padding-bottom: 2px;
  }

  @media (max-width: 991px) {
    padding-top: 20px;

    &__prev,
    &__next {
      top: -10px;
    }
  }
}

.testimonial-slider {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  max-width: 1140px;
  margin: 0 auto;

  &__pagination {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: #000;
      }
    }
  }

  &__text {
    font-size: 22px;
    color: #888;
    padding-left: 80px;
    position: relative;
    min-height: 50px;
    padding-bottom: 30px;

    &::before {
      content: "";
      display: block;
      width: 50px;
      height: 50px;
      background: url("../images/icon-double-quote.png") center/contain no-repeat;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__name {
    text-align: center;
    color: #888;
  }

  @media (max-width: 991px) {
    &__text {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 30px;

    &__text {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }
}

.team-slider {
  position: relative;
  z-index: 1;

  @include slider-nav-1();

  &__prev,
  &__next {
    &.swiper-button-disabled {
      display: none;
    }
  }
}
