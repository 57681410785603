$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;
      & > [class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}

.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row dense;

  &__col {
    &:nth-child(1),
    &:nth-child(4) {
      grid-row: span 2;
    }

    &:nth-child(3) {
      grid-column: 2 / span 1;
    }

    &:nth-child(4) {
      grid-row: span 2;
      grid-column: 3 / span 1;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);

    &__col {
      grid-column: span 1 !important;
    }
  }
}

.card-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .card-1__frame::before {
    padding-bottom: 100%;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.grid {
  &__main {
    margin-bottom: 30px;
  }

  @media (min-width: 991px) {
    display: flex;
    justify-content: space-between;

    &__main {
      flex: 0 0 calc(100% - 270px);
      max-width: calc(100% - 270px);
      margin-bottom: 0;
    }

    &__aside {
      order: -1;
      flex: 0 0 240px;
      max-width: 240px;
    }
  }

  @media (min-width: 1200px) {
    &__main {
      flex: 0 0 calc(100% - 300px);
      max-width: calc(100% - 300px);
    }

    &__aside {
      flex: 0 0 270px;
      max-width: 270px;
    }
  }
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 30px;

  @media (max-width: 1399px) {
    grid-template-columns: 1fr;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;

  @media (max-width: 1699px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1399px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-gap: 16px;
  }
}

.product-grid-2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (max-width: 1399px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}
