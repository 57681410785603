.product {
  &__left {
    width: 200px;
    margin-right: 30px;
  }

  &__frame {
    @include frame("rect", 125%);
    margin-bottom: 8px;

    img {
      &:nth-child(2) {
        opacity: 0;
        transition: 0.3s ease-in;
      }
    }

    &:hover img:nth-child(2) {
      opacity: 1;
    }

    .badge {
      font-size: 12px;
      line-height: 22px;
      padding: 0 5px;
      font-weight: 400;
      border-radius: 0;
      color: $white;
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 10;
    }
  }

  &__name {
    font-size: 14px;
    color: #888;
    text-align: center;
    margin-bottom: 8px;
  }

  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #111;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 8px;

    del {
      font-size: 0.75em;
      margin-left: 4px;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $danger;
    font-size: 12px;
    margin-bottom: 8px;
  }

  &__colors {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  &__sizes {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .button {
      position: relative;
      z-index: 10;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid $border-color;
    color: #888;
    transition: 0.3s;

    &:hover {
      color: $primary;
      border-color: $primary;
      text-decoration: none;
    }

    &:first-child {
      margin-right: 10px;
      transform: translateX(50px);
    }

    &:last-child {
      margin-left: 10px;
      transform: translateX(-50px);
    }

    &--show {
      transform: unset !important;
    }
  }

  &:hover &__btn {
    &:first-child,
    &:last-child {
      transform: translateX(0);
    }
  }

  &.media &__name {
    text-align: left;
  }

  &.media &__price,
  &.media &__info,
  &.media &__colors,
  &.media &__sizes {
    justify-content: flex-start;
  }

  &.media &__footer {
    justify-content: flex-start;
  }

  &__countdown {
    display: flex;
    width: 100%;
    z-index: 10;
    padding: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    &--static {
      position: static;
      max-width: 250px;
      padding: 0;
      margin-bottom: 8px;
    }

    & > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 60px;
      border: 1px solid $border-color;
      color: $primary;
      background-color: $white;
      font-size: 12px;
      line-height: 1.2;

      &:not(:last-child) {
        margin-right: 4px;
      }

      & > span:first-child {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1399px) {
    &__countdown {
      & > span {
        height: 46px;
        font-size: 10px;

        & > span:first-child {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    &__left {
      width: 130px;
      margin-right: 15px;
    }

    &__btn {
      width: 35px;
      height: 35px;
      transform: unset !important;
    }
  }
}

.product-2 {
  & + & {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $border-color;
  }

  &__frame {
    @include frame("rect", 125%);
    width: 95px;
    margin-right: 20px;

    img {
      &:nth-child(2) {
        opacity: 0;
        transition: 0.3s ease-in;
      }
    }

    &:hover img:nth-child(2) {
      opacity: 1;
    }

    .badge {
      font-size: 12px;
      line-height: 22px;
      padding: 0 5px;
      font-weight: 400;
      border-radius: 0;
      color: $white;
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 10;
    }
  }

  &__name {
    font-size: 14px;
    color: #888;
    margin-bottom: 8px;
  }

  &__price {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #111;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 8px;

    del {
      font-size: 0.75em;
      margin-left: 4px;
    }
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: #888;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.product-3 {
  &__frame {
    @include frame("rect", 124%);
    margin: 0 auto;
    max-width: 100%;
    width: 110px;
    margin-bottom: 10px;
    margin-bottom: 16px;
  }

  &__name {
    font-size: 16px;
    color: #000;
    transition: 0.3s;
    margin-bottom: 4px;

    a {
      color: #000;
    }

    a:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__stars {
    font-size: 18px;
  }

  &__review {
    font-size: 12px;
    color: #000;
  }

  &__price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #888;
    text-align: center;
    line-height: 30px;
    margin-top: 16px;

    span {
      color: $primary;
      font-size: 20px;
      margin-left: 4px;
    }
  }

  &__btn {
    font-size: 20px;
    color: #888;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.product-4 {
  & + & {
    margin-top: 16px;
  }

  &__frame {
    @include frame("rect", 120%);
    width: 100px;
    margin-right: 20px;
  }

  &__name {
    color: #888;
    font-size: 14px;
    margin-bottom: 4px;
  }

  &__quantity {
    color: #888;
    margin-bottom: 4px;
  }

  &__price {
    font-size: 20px;
    font-weight: 600;

    del {
      margin-left: 8px;
      font-size: 0.7em;
    }
  }
}

.product-5 {
  & + & {
    margin-top: 16px;
  }

  &__frame {
    @include frame("rect", 120%);
    width: 100px;
    margin-right: 20px;
  }

  &__name {
    color: #888;
    font-size: 14px;
    margin-bottom: 4px;
  }

  &__price {
    font-size: 20px;
    font-weight: 600;

    span {
      color: $primary;
    }

    del {
      margin-left: 8px;
      font-size: 0.7em;
    }
  }
}

.news {
  &__frame {
    @include frame("rect", 80%);
    @include frame("fade", rgba(#fff, 0.5));
    margin-bottom: 24px;
  }

  &__title {
    @include max-line(1);
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.2;
    max-height: 1.2em;
  }

  &__desc {
    @include max-line(3);
    color: #888;
    margin-bottom: 12px;
  }

  &__info {
    font-size: 12px;
    text-align: center;
    color: #888;
  }

  @media (max-width: 767px) {
    &__frame {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 16px;
    }

    &__desc {
      font-size: 12px;
    }
  }
}

.card-1 {
  height: 100%;
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 75%);
    height: 100%;
    position: relative;
    z-index: -3;
  }

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    color: #333;

    &:hover {
      color: #333;
      text-decoration: none;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(#fff, 0.3);
      transition: 0.3s ease-out;
      transform: scale(0);
      opacity: 0;
      position: absolute;
      z-index: -2;
      top: 0;
      left: 0;
    }

    &:hover::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 112px 15px;
  }

  &__label {
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__title-1 {
    font-size: 120px;
    font-weight: 700;
    color: $primary;
    text-align: center;
    line-height: 1.1;
    letter-spacing: 0.2em;
    transition: 0.3s ease-out;
  }

  &__overlay:hover &__title-1 {
    color: #333;
  }

  &__title-2 {
    font-size: 30px;
    font-weight: 700;
    color: $white;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-align: center;
  }

  &__title-3 {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  &__title-4 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    line-height: 1.2;
    margin-bottom: 35px;
  }

  &__desc {
    font-size: 14px;
    text-align: center;
    color: #777;
    margin-bottom: 34px;
  }

  &--menu &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }

  &--menu &__title-4 {
    margin-bottom: 24px;
    color: $white;
  }

  &--menu &__label {
    color: $white;
  }

  @media (max-width: 1699px) {
    &__content {
      padding: 70px 15px;
    }

    &__title-1 {
      font-size: 100px;
    }
  }

  @media (max-width: 1499px) {
    &__content {
      padding: 50px 15px;
    }

    &__title-1 {
      font-size: 80px;
    }

    &__title-2 {
      font-size: 24px;
    }

    &__title-3 {
      font-size: 40px;
    }

    &__title-4 {
      margin-bottom: 24px;
    }

    &__label {
      margin-bottom: 8px;
    }

    &__desc {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1199px) {
    &__content {
      padding: 40px 15px;
    }

    &__title-1 {
      font-size: 70px;
    }

    &__title-2 {
      font-size: 22px;
    }

    &__title-3 {
      font-size: 30px;
      margin-bottom: 10px;
    }

    &__title-4 {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &__desc {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 991px) {
    &__content {
      padding: 30px 10px;
    }

    &__title-1 {
      font-size: 55px;
    }

    &__title-2 {
      font-size: 20px;
    }

    &__title-3 {
      font-size: 24px;
    }

    &__desc {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    &__title-3 {
      font-size: 35px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
}

.as-banner {
  display: block;
  width: 240px;
  max-width: 100%;
  position: relative;
  z-index: 1;

  &__name {
    font-size: 30px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%) rotate(90deg);
    letter-spacing: 6px;
  }

  &__link {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    color: $white;
    text-decoration: underline;
    transition: 0.3s;

    &:hover {
      color: $primary;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.review {
  border: 1px solid $border-color;
  padding: 20px 30px;

  & + & {
    margin-top: 16px;
  }

  &__info {
    color: #888;
  }

  &__text {
    color: #888;
    margin-top: 8px;
  }
}

.team {
  &__frame {
    @include frame("square");
    @include frame("fade", rgba(#fff, 0.5));
    margin-bottom: 20px;
  }

  &__name {
    font-size: 22px;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }

  &__intro {
    text-align: center;
    color: #888;
  }

  @media (max-width: 1399px) {
    &__name {
      font-size: 18px;
    }

    &__intro {
      font-size: 13px;
    }
  }
}

.as-comment {
  & + & {
    margin-top: 20px;
  }

  &__name {
    font-size: 13px;
    font-weight: 600;
  }

  &__pos {
    font-size: 13px;
    color: #888;
    margin-bottom: 8px;

    a {
      color: $primary;
      transition: 0.3s;

      &:hover {
        color: $dark;
        text-decoration: none;
      }
    }
  }

  &__text {
    @include max-line(2);
    font-size: 13px;
    color: #888;
  }
}

.comment {
  border: 1px solid $border-color;
  padding: 24px 30px;

  & + & {
    margin-top: 20px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
  }

  &__name {
    font-size: 22px;
    font-weight: 600;
    margin-right: 30px;
  }

  &__time {
    color: #888;
  }

  &__text {
    color: #888;
  }

  @media (max-width: 767px) {
    padding: 16px 20px;

    &__header {
      margin-bottom: 12px;
      margin-right: 16px;
    }

    &__time {
      font-size: 13px;
    }

    &__name {
      font-size: 18px;
    }
  }
}
