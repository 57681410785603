.about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f5f5f5;

  &__frame {
    @include frame("rect", 66.66%);
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 10%;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 48px;
  }

  &__desc {
    color: #888;
    text-align: center;
  }

  @media (max-width: 1399px) {
    &__title {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 575px) {
    &__content {
      padding: 30px 15px;
    }
  }
}

.about-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #f5f5f5;

  &__frame {
    @include frame("square");
    height: 100%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 60px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    img {
      height: 50px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
  }

  &__desc {
    color: #888;
    text-align: center;
  }

  @media (max-width: 1399px) {
    &__content {
      padding: 40px;
    }

    &__icon {
      margin-bottom: 16px;
    }

    &__title {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 1199px) {
    &__content {
      padding: 24px;
    }

    &__icon {
      margin-bottom: 10px;

      img {
        height: 40px;
      }
    }

    &__title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__desc {
      font-size: 12px;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto;
  }
}

.about-count {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  padding: 90px 0;

  &__number {
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    color: $primary;
    letter-spacing: 6px;
  }

  &__text {
    font-size: 22px;
    font-weight: 600;
  }

  @media (max-width: 1399px) {
    padding: 60px 0;
  }

  @media (max-width: 1199px) {
    &__number {
      font-size: 40px;
    }

    &__text {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;

    &__col {
      padding: 0 5px;
    }

    &__number {
      font-size: 30px;
      letter-spacing: 1px;
    }

    &__text {
      font-size: 14px;
    }
  }
}
