.table {
  margin-bottom: 0;
  th,
  td {
    vertical-align: middle;
  }
  thead {
    th {
      border-bottom: 0;
    }
  }
}

// table responsive
@mixin table-responsive {
  thead {
    display: none;
  }

  &,
  tbody,
  tr,
  td {
    display: block;
  }
  tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  td {
    position: relative;
    padding: 8px 8px 8px 30%;
    text-align: left;
    border: 0;

    &:not(:last-child) {
      border-bottom: 1px dashed $border-color;
    }

    &::before {
      content: attr(data-label);
      display: block;
      width: 30%;
      text-align: right;
      font-weight: bold;
      padding: 8px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

@each $key, $val in $grid-breakpoints {
  @media (max-width: #{$val - 1}) {
    .table-expand-#{$key} {
      @include table-responsive;
    }
  }
}
