.as-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    @include max-line(2);
    display: block;
    color: $primary;
    transition: 0.3s;

    &:hover {
      color: $dark;
      text-decoration: none;
    }
  }
}

.as-info {
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: 16px;
  margin-bottom: 20px;

  &__frame {
    @include frame("circle");
    width: 50px;
    margin-right: 20px;
  }

  &__name {
    font-weight: 600;
  }

  &__edit {
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.acc-menu {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__sub &__item {
    margin-top: 8px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    color: $gray-800;

    &.active,
    &:hover {
      color: $primary;
      text-decoration: none;
    }

    img {
      width: 25px;
      height: 25px;
      margin-right: 15px;
      object-fit: cover;
      object-position: 50% 50%;
    }

    .fa {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $white;
      background-color: #000;
      font-size: 12px;
      line-height: 24px;
    }
  }

  &__sub {
    list-style: none;
    margin: 0;
    padding-left: 40px;
  }
}

.acc-form {
  &--short {
    max-width: 550px;
    margin: 0 auto;
  }

  &__label {
    text-align: right;
    color: #757575;
    padding-top: 4px;
    text-transform: capitalize;
  }

  &__text {
    padding: 4px 0;

    a {
      &,
      &:hover {
        color: #2e74b9;
        text-decoration: underline;
      }
    }
  }
}

.acc-section {
  height: 100%;
  overflow: hidden;
  background-color: $white;
  border-radius: 4px;
  @include shadow("xs");

  &__header {
    padding: 0 30px;

    &-inner {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      border-bottom: 1px solid $border-color;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__body {
    padding: 30px;
  }

  &__title {
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  &__subtitle {
    color: #666;
  }
}

.acc-grid {
  display: flex;
  align-items: flex-start;

  &__main {
    flex: 1;
    padding-right: 50px;
  }

  &__aside {
    flex: 0 0 280px;
    border-left: 1px solid $border-color;
    padding: 16px 50px 0;
  }
}

.address {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
  }

  &__info {
    margin-right: 20px;

    table {
      td {
        padding: 6px 0;

        &:first-child {
          white-space: nowrap;
          text-align: right;
          color: #757575;
          padding-right: 20px;
        }

        .badge {
          padding: 4px 8px;
        }
      }
    }
  }

  &__btns {
    white-space: nowrap;
    text-align: right;
  }

  &__toggle {
    color: $gray-800;
    text-decoration: underline;
    font-size: 16px;
    white-space: nowrap;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    padding: 0 20px;
    line-height: 33px;
    border-radius: 2px;
    border: 1px solid $border-color;
    color: $gray-600;
    transition: 0.3s;
    white-space: nowrap;

    &:hover {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;

    &__info {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.announcement {
  padding: 20px 30px;

  &:hover,
  &.read {
    background-color: #f8f8f8;
  }

  &__frame {
    @include frame("rect", 100%);
    width: 80px;
    margin-right: 20px;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__time {
    color: #757575;
  }

  &__desc {
    @include max-line(3);
  }

  &__btn {
    margin-left: 20px;
  }
}

.avatar-input {
  &__preview {
    @include frame("circle");
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    max-width: 100%;
    margin: 0 auto 20px;
    background: url("../images/avatar-placeholder.png") center/cover no-repeat;
  }

  &__label {
    display: flex;
    justify-content: center;

    label {
      display: inline-block;
      position: relative;
      cursor: pointer;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 38px;
      border-radius: 2;
      border: 1px solid $border-color;
      padding: 0 20px;
    }
  }

  &__text {
    margin-top: 4px;
    color: #999;
  }
}
