.feature {
  height: 100%;
  border: 1px solid $border-color;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  color: #333;
  padding: 65px 20px;

  &:hover {
    color: #333;
    border-color: #000;
    text-decoration: none;
  }

  &__icon {
    font-size: 50px;
    line-height: 1;
    margin-right: 24px;
    color: #888;
    transition: 0.3s;
  }

  &:hover &__icon {
    color: $primary;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
  }

  &__desc {
    margin-top: 8px;
    color: #888;
  }

  @media (max-width: 1399px) {
    padding: 40px 20px;

    &__title {
      font-size: 18px;
    }

    &__desc {
      margin-top: 4px;
    }
  }

  @media (max-width: 1199px) {
    padding: 30px 15px;

    &__icon {
      font-size: 30px;
      margin-right: 15px;
    }

    &__body {
      min-width: 215px;
    }

    &__title {
      font-size: 16px;
    }

    &__desc {
      font-size: 13px;
    }
  }
}

.pd-tabs {
  margin-bottom: 24px;

  .nav-item {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .nav-link {
    font-weight: 700;
    padding: 0;
    color: #999;

    &:hover,
    &.active {
      color: #222;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    display: block;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.partners {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  border: 1px solid $border-color;

  &__frame {
    display: block;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      max-width: 70%;
      max-height: 70%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.4s;
      filter: saturate(0);
      opacity: 0.5;
    }

    &:hover img {
      filter: saturate(1);
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
