.cart {
  &__frame {
    @include frame("rect", 120%);
    width: 80px;
    margin: 0 auto;
  }

  &__remove {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: #000;
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__name {
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  &__title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      height: 30px;
      margin-right: 15px;
    }
  }

  &__subhead {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 16px;
  }

  &__info {
    border-bottom: 1px solid $border-color;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  &__price {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  .quantity {
    &__btn {
      background-color: $light;
    }

    &__input {
      background-color: $light;
    }
  }

  thead {
    th {
      font-weight: 600;
    }
  }

  @media (max-width: 767px) {
    &__frame {
      width: 75px;
      background-color: #fff;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    &__remove {
      position: relative;
      top: 4px;
    }

    table,
    tbody,
    tr,
    td {
      display: block;
    }

    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 10px 10px 10px 95px;
      min-height: 110px;

      &:nth-child(odd) {
        background-color: $light;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }

    td {
      border: 0;
      padding: 0;

      &:nth-child(2) {
        flex: 0 0 100%;
        margin-bottom: 8px;
      }

      &:nth-child(4) {
        display: none;
      }

      &:nth-child(5) {
        padding: 0 0 0 10px;
        text-align: right;
        margin-left: auto;
      }

      &:nth-child(6) {
        margin-left: auto;
      }
    }

    .quantity {
      &__input {
        height: 30px;
        max-width: 30px;
      }
    }
  }
}

.cart-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
