.brand-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;

  &__item {
    border: 1px solid $border-color;
    padding: 20px 15px 15px;
  }

  &__frame {
    @include frame("rect", 66.66%);
    max-width: 105px;
    margin: 0 auto 15px;

    img {
      object-fit: contain;
      transition: 0.3s;
      filter: saturate(0);
      opacity: 0.6;
    }

    &:hover img {
      filter: saturate(1);
      opacity: 1;
    }
  }

  &__name {
    font-size: 18px;
    text-align: center;
    font-weight: 600;

    a {
      color: $primary;
      transition: 0.3s;

      &:hover {
        color: $dark;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1399px) {
    &__name {
      font-size: 16px;
    }
  }

  @media (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 991px) {
    &__item {
      padding: 15px 10px 10px;
    }

    &__frame {
      margin-bottom: 10px;
    }

    &__name {
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
