.signin {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__block {
    padding: 0 30px;
    max-width: 420px;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__text {
    color: #888;
    margin-bottom: 16px;
  }

  &__social {
    @include btn("shine");
    display: flex;
    align-items: center;
    background-color: $google-plus;
    color: $white;
    padding: 4px;

    & + & {
      margin-top: 16px;
    }

    .fa {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-right: 1px solid rgba(#fff, 0.5);
      margin-right: 20px;
    }

    &--facebook {
      background-color: $facebook;
    }

    &--instagram {
      background-color: $instagram;
    }

    &--twitter {
      background-color: $twitter;
    }
  }

  @media (max-width: 991px) {
    margin: 0;

    &__block {
      flex: 0 0 100%;
      padding: 0;

      &:not(:last-child) {
        border-right: 0;
        margin-bottom: 30px;
      }
    }
  }
}
