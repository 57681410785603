.footer {
  padding: 60px 0 0;
  background-color: #181818;

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }

  &__logo {
    display: inline-flex;
    margin-bottom: 24px;

    img {
      height: 30px;
    }
  }

  &__addr {
    font-size: 14px;
    line-height: 24px;
    color: #888;

    a {
      color: $primary;
      transition: 0.3s;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $white;
    margin-bottom: 16px;
  }

  &__copyright {
    font-size: 12px;
    color: #888;
    background-color: #000;
    padding: 16px 0;
    margin-top: 55px;
  }

  @media (max-width: 1399px) {
    &__grid {
      grid-template-columns: 5fr 3fr 4fr 5fr;
      grid-gap: 20px;
    }
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 767px) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}

.f-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 24px;
    color: $primary;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.f-form {
  &__label {
    font-weight: 700;
    color: $white;
    margin-bottom: 16px;
  }

  .input-group-prepend {
    .input-group-text {
      background: 0;
      color: $white;
      border: 1px solid #888;
      border-right: 0;
      border-radius: 0;
    }
  }

  .form-control {
    background: 0;
    color: $white;
    border: 1px solid #888;
    border-left: 0;
    padding-left: 0;

    &::placeholder {
      color: #888;
    }
  }

  .input-group-append {
    margin-left: 4px;

    .input-group-text {
      border: 0;
      background-color: $primary;
      color: $white;
      border-radius: 0;
      padding: 0 20px;
      font-weight: 700;
      transition: 0.3s;

      &:hover {
        background-color: #000;
        text-decoration: none;
      }
    }
  }
}

.f-social {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $white;
    background-color: #464646;
    transition: 0.3s;
    font-size: 16px;

    &:hover {
      background-color: #fff;
      color: #333;
    }

    &:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 1399px) {
    &__item {
      width: 40px;
      height: 40px;
    }
  }
}
